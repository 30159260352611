import * as React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { addSeconds, format } from 'date-fns';
import useSpotifyAuth from './hooks/useSpotifyAuth';
import useSpotifyCurrentSong from './hooks/useSpotifyCurrentSong';
import useSpotifyTrackFavoriteStatus from './hooks/useSpotifyTrackFavoriteStatus';

const Container = styled.div`
  display: flex;
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
  font-family: spotify-circular, spotify-circular-cyrillic, spotify-circular-arabic, spotify-circular-hebrew,
    'Proxima Nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 2.5em;
  background-color: rgba(0, 0, 0, 0.82);
  color: #dcdcdc;

  height: 256px;
  width: 1024px;
`;

const ImgContainer = styled.div`
  flex: 0 1 auto;
  display: flex;
  height: 100%;
  max-height: 100%;

  & img {
    width: 256px;
    height: 256px;
    object-fit: cover;
  }
`;

const SongInfoContainer = styled.div`
  flex: 2 1 auto;
  display: flex;
  flex-direction: column;
  padding: 30px 30px;
  justify-content: space-between;
`;

const SongTextContainer = styled.div`
  display: flex;
  flex-direction: column;

  .artist {
    font-weight: 400;
    color: #aaa;
    font-size: 0.9em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 700px;
  }
`;

const SongTitleContainer = styled.div`
  display: flex;
  align-items: center;

  .song {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 1.1em;
    margin-bottom: 5px;
    white-space: nowrap;
    display: block;
    overflow: hidden;
    max-width: 615px;
  }
`;

const SongExtrasContainer = styled.div`
  margin-left: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 72px;

  .explicit {
    content: 'E';
    font-size: 20px;
    color: #888;
    border: 2px solid #888;
    border-radius: 5px;
    padding: 0 7px;
  }

  .favorited {
    width: 32px;
    height: 32px;
    font-size: 32px;
    line-height: 32px;
    margin-left: 10px;
    text-align: center;
    &.spoticon-heart-active-32 {
      color: #1DB954;
    }
  }
`;

const CSSMarqueeKeyframes = (props) => keyframes`
  5% {
    opacity: 1;
  }
  20% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 1;
    transform: translateX(-${props.length * 1.2}px);
  }
`;

const CSSMarquee = styled.div`
  white-space: nowrap;
  opacity: 0;
  transform: translateX(0);
  animation: ${({ length }) => css`${CSSMarqueeKeyframes({ length })} ${length / 100}s linear infinite`};
`;

const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  span {
    position: absolute;
    font-size: 0.8em;
    font-weight: 400;
    color: #ccc;
    top: -42px;
    left: 2px;
  }

  span.duration {
    left: initial;
    right: 2px;
  }
`;

const ProgressBarOuter = styled.div`
  width: 100%;
  height: 0.85em;
  background-color: #ddd;
  border-radius: 5px;
  overflow: hidden;
`;

const ProgressBarInner = styled.div.attrs(props => ({
  style: {
    width: `${props.progress * 100}%`,
  },
}))`
  height: 100%;
  background-color: #1DB954;
  transition: width linear ${({shouldAnimate}) => shouldAnimate ? '1000ms' : '0'};
`;

const LogInButton = styled.button`
  width: 100%;
  border: none;
  outline: none;
  font-size: 2.5em;
  font-family: inherit;
  background-color: #1DB954;
  color: #333;
  cursor: pointer;
`;

const Loading = styled.div`
  width: 100%;
  font-size: 2.5em;
  font-family: inherit;
  background-color: #1DB954;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const artistsToString = (artists) => {
  return artists.map(a => a.name).join(', ');
};

const msToTime = (ms) => {
  const date = addSeconds(new Date(0), ms / 1000);
  return format(date, 'mm:ss');
};

const App = () => {
  const [prevSong, setPrevSong] = React.useState(null);
  const [prevProgress, setPrevProgress] = React.useState(0);
  const [isSongNameOverflow, setSongNameOverflow] = React.useState(false);
  const [songNameLength, setSongNameLength] = React.useState(0);
  const [shouldAnimateProgress, setShouldAnimateProgress] = React.useState(false);
  const { loggedIn, login } = useSpotifyAuth();
  const { song, error } = useSpotifyCurrentSong(1000);
  const { isFavorite } = useSpotifyTrackFavoriteStatus(song && song.item && song.item.id);
  const songTitleRef = React.useRef();

  React.useEffect(() => {
    if (!song || !song.item || !song.item.name) return;
    if (!songTitleRef) return;
    if (prevSong && song.item.name === prevSong.item.name) return;

    setPrevSong(song);

    const songTitleElem = songTitleRef.current;

    if (!songTitleElem) return;

    setSongNameOverflow(songTitleElem.offsetWidth <= songTitleElem.scrollWidth && songTitleElem.offsetWidth === 615);
    setSongNameLength(songTitleElem.scrollWidth);
  }, [prevSong, song, songTitleRef]);

  React.useEffect(() => {
    if (!song || !prevSong || !song.item || !prevSong.item) return setShouldAnimateProgress(true);

    setPrevProgress(song.progress_ms);

    // don't animate immediately after song change
    if (song.item.name !== prevSong.item.name) {
      return setShouldAnimateProgress(false);
    }

    // don't animate for first two seconds of song
    if (song.progress_ms < 2000) {
      return setShouldAnimateProgress(false);
    }

    // don't animate for last two seconds of song
    if (song.item.duration_ms - song.progress_ms < 2000) {
      return setShouldAnimateProgress(false);
    }

    // don't animate for a change greater than 2 seconds
    if (Math.abs(song.progress_ms - prevProgress) > 2000) {
      return setShouldAnimateProgress(false);
    }

    return setShouldAnimateProgress(true);
  }, [song]);

  if (!loggedIn) {
    return (
      <Container>
        <LogInButton onClick={login}>Log In to Spotify</LogInButton>
      </Container>
    );
  }

  if (!song && !error) {
    return (
      <Container>
        <Loading>Loading...</Loading>
      </Container>
    );
  }

  if (!song.item) {
    return (
      <Container>
        <Loading>No Data</Loading>
      </Container>
    )
  }

  let albumImage = 'https://cdn.last.fm/flatness/responsive/2/noimage/default_album_300_g4.png';
  if (song && song.item && song.item.album && song.item.album.images && song.item.album.images.length) {
    albumImage = song.item.album.images[0].url
  }

  return (
    <>
      <Container>
        <ImgContainer>
          <img src={albumImage} onMouseDown={e => e.preventDefault()} />
        </ImgContainer>
        <SongInfoContainer>
          <SongTextContainer>
            <SongTitleContainer>
              <span className={`song`} ref={songTitleRef}>
                { isSongNameOverflow ? (
                  <CSSMarquee length={songNameLength}>{song.item.name}</CSSMarquee>
                ) : song.item.name }
              </span>
              <SongExtrasContainer>
                { song.item.explicit ? <span className="explicit">E</span> : null }
                <span className={`favorited ${isFavorite ? 'spoticon-heart-active-32' : 'spoticon-heart-32'}`}></span>
              </SongExtrasContainer>
            </SongTitleContainer>
            <span className="artist">{artistsToString(song.item.artists)}</span>
          </SongTextContainer>
          <ProgressBarContainer>
            <span className="progress">{msToTime(song.progress_ms)}</span>
            <span className="duration">{msToTime(song.item.duration_ms)}</span>
            <ProgressBarOuter>
              <ProgressBarInner progress={song.progress_ms / song.item.duration_ms} shouldAnimate={shouldAnimateProgress} />
            </ProgressBarOuter>
          </ProgressBarContainer>
        </SongInfoContainer>
      </Container>
    </>
  );
};

export default App;
