import { useEffect } from 'react';
import createPersistedState from 'use-persisted-state';
const useTokenState = createPersistedState('token');
const useStateKeyState = createPersistedState('stateKey');

const generateRandomString = (length) => {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

const getHashParams = () => {
  const hashParams = {};
  let e;
  const r = /([^&;=]+)=?([^&;]*)/g;
  const q = window.location.hash.substring(1);
  while (e = r.exec(q)) {
    hashParams[e[1]] = decodeURIComponent(e[2]);
  }
  return hashParams;
}

const useSpotifyAuth = () => {
  const [token, setToken] = useTokenState(null);
  const [stateKey, setStateKey] = useStateKeyState(null);

  useEffect(() => {
    const { access_token, state } = getHashParams() as any;

    if (access_token && (state === null || state !== stateKey)) {
      setStateKey(null);
      window.history.pushState('','','/');
      return;
    }

    if (access_token) {
      setStateKey(null);
      setToken(access_token);
      window.history.pushState('','','/');
    }
  }, [stateKey]);

  const logout = () => {
    setToken(null);
  };

  const login = () => {
    const clientId = 'bf39fbcd1444496fb93f244498b44459';
    const redirectUri = window.location.origin;

    const state = generateRandomString(16);
    setStateKey(state);

    const scope = 'user-read-private user-read-email user-read-playback-state user-read-playback-position user-library-read';

    const urlParams = new URLSearchParams({
      'response_type': 'token',
      'client_id': clientId,
      'scope': scope,
      'redirect_uri': redirectUri,
      'state': state,
    });

    window.location.assign(`https://accounts.spotify.com/authorize?${urlParams.toString()}`);
  }

  return {
    login,
    loggedIn: Boolean(token),
    logout,
    token,
  };
}

export default useSpotifyAuth;
