import { useState, useEffect } from 'react';
import useInterval from './useInterval';
import useSpotifyAuth from './useSpotifyAuth';

const useSpotifyTrackFavoriteStatus = (trackId?: string, interval = 5000) => {
  const [lastTrackId, setLastTrackId] = useState(trackId);
  const [ranOnce, setRanOnce] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [error, setError] = useState(null);
  const [isFetching, setFetching] = useState(false);
  const { loggedIn, token, login, logout } = useSpotifyAuth();

  useEffect(() => {
    if (!error) return;

    if (error.status === 401) {
      setError(null);
      logout();
      login();
    }
  }, [error, logout, login]);

  useEffect(() => {
    if (lastTrackId !== trackId) {
      setRanOnce(false);
    }
    setLastTrackId(trackId);
  }, [lastTrackId, trackId]);

  useInterval(() => {
    if (isFetching || !loggedIn || !trackId) return;
    setRanOnce(true);

    fetch(`https://api.spotify.com/v1/me/tracks/contains?ids=${trackId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((d) => {
        if (d.error) {
          setIsFavorite(false);
          setError(d.error);
          return;
        }

        setIsFavorite(d[0]);
      })
      .catch((e) => {
        setError(e);
        setIsFavorite(false);
      })
      .then(() => {
        setFetching(false);
      });
  }, loggedIn ? (ranOnce ? interval : 1) : null);

  return {
    isFavorite,
    error,
  };
}

export default useSpotifyTrackFavoriteStatus;
