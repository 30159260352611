import { useState, useEffect } from 'react';
import useInterval from './useInterval';
import useSpotifyAuth from './useSpotifyAuth';

const EMPTY_SONG_DATA = {
  progress_ms: 0,
  item: {
    artists: [
      {
        name: '',
      },
    ],
    images: [],
    name: 'No song playing',
    duration_ms: 0,
  },
  is_playing: false,
}

const useSpotifyCurrentSong = (interval = 5000) => {
  const [ranOnce, setRanOnce] = useState(false);
  const [song, setSong] = useState(null);
  const [error, setError] = useState(null);
  const [isFetching, setFetching] = useState(false);
  const { loggedIn, token, login, logout } = useSpotifyAuth();

  useEffect(() => {
    if (!error) return;

    if (error.status === 401) {
      setError(null);
      logout();
      login();
    }
  }, [error, logout, login]);

  useInterval(() => {
    if (isFetching || !loggedIn) return;
    setRanOnce(true);

    fetch('https://api.spotify.com/v1/me/player/currently-playing', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((d) => {
        if (d.error) {
          setSong(EMPTY_SONG_DATA);
          setError(d.error);
          return;
        }

        setSong(d);
        setError(null);
      })
      .catch((e) => {
        if (e.message.includes('JSON.parse')) {
          setError(null);
          return setSong(EMPTY_SONG_DATA);
        }
        setError(e);
        setSong(EMPTY_SONG_DATA);
      })
      .then(() => {
        setFetching(false);
      });
  }, loggedIn ? (ranOnce ? interval : 1) : null);

  return {
    song,
    error,
  };
}

export default useSpotifyCurrentSong;
